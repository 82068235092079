.hexa > div {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}

.hexa {
    /* background: #ff0; */
    background: lightgrey;
    position: relative;
    overflow: hidden;
    visibility: hidden;
    display: block;
}

.hexa:before {
    content: '';
    position: absolute;
    visibility: visible;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    -webkit-transform: rotate(-30deg) skewX(30deg) scale(1.155, 0.866);
    transform: rotate(-30deg) skewX(30deg) scale(1.155, 0.866);
    background: inherit;
}

.hexa:after {
    content: '';
    display: block;
    padding-top: 115.4734411%;
}

.hexa > * {
    visibility: visible;
}

.hexa h3 {
    font-size: 38px;
    font-weight: bold;
    margin: 0 0 10px 0;
    white-space: nowrap;
    padding: 0 25px;
    text-align: left;
}

.hexa p {
    font-size: 16px;
    text-align: left;
    padding: 0 25px;
}

.hexa .icon {
    -webkit-transition: all .3s linear;
    -o-transition: all .3s linear;
    transition: all .3s linear;
    position: absolute;
    top: -38px;
    right: 10px;
    z-index: 0;
    font-size: 75px;
    color: rgba(0, 0, 0, 0.15);
}

@media (max-width: 499px) {
    .hexa h3 {
        font-size: 80px;
    }

    .hexa p {
        font-size: 25px;
    }

    .hexa .icon {
        font-size: 130px;
    }
}

@media (min-width: 500px) and (max-width: 719px) {

    .hexa {
        float: left;
        width: 48%;
        margin-right: 1%;
        margin-top: -6.2376%;
        margin-bottom: -6.2376%;
    }

    .hexa.hexa-center,
    .hexa.hexa-left,
    .hexa.hexa-left-2,
    .hexa-fix-4 {
        margin-left: 26%;
    }

    .hexa.hexa-center-left,
    .hexa-fix-2 {
        margin-left: 1.5%;
    }

}

@media (min-width: 720px) and (max-width: 879px) {
    .hexa {
        float: left;
        width: 32%;
        margin-right: 1%;
        margin-top: -5.2376%;
        margin-bottom: -3.2376%;
    }

    .hexa.hexa-center {
        margin-left: 34%;
    }

    .hexa.hexa-center-left {
        margin-left: 17.5%;
    }

    .hexa.hexa-left {
        margin-left: 1%;
    }

    .hexa.hexa-left-2 {
        margin-left: 17.5%;
    }

    .hexa-fix-3 {
        margin-left: 1%;
    }
}

@media (min-width: 880px) and (max-width: 1099px) {
    .hexa {
        float: left;
        width: 32%;
        margin-right: 1%;
        margin-top: -5.2376%;
        margin-bottom: -3.2376%;
    }

    .hexa.hexa-center {
        margin-left: 34%;
    }

    .hexa.hexa-center-left {
        margin-left: 17.5%;
    }

    .hexa.hexa-left {
        margin-left: 1%;
    }

    .hexa.hexa-left-2 {
        margin-left: 17.5%;
    }

    .hexa-fix-3 {
        margin-left: 1%;
    }
}

@media (min-width: 1100px) {
    .hexa {
        float: left;
        width: 18.4%;
        margin-right: 2%;
        margin-top: -2.31162%;
        margin-bottom: -1.31162%;
    }

    .hexa.hexa-center {
        margin-left: 40.8%;
    }

    .hexa.hexa-center-left {
        margin-left: 30.6%;
    }

    .hexa.hexa-left {
        margin-left: 20.4%;
    }

    .hexa.hexa-left-2 {
        margin-left: 10.2%;
    }
}